<template>
<Dialog v-model:visible="visible" modal header="Request Links Removal" :style="{ width: '50vw' }">
    <!-- Asset Selection -->
    <p class="mt-3 font-bold">Select Asset</p>
    <AutoComplete class="w-full text-lg" v-model="asset" id="asset_name" name="asset_name" :suggestions="assets"
                  :dropdown="true" @complete="searchAssets" optionLabel="name" placeholder="Asset" forceSelection />

    <!-- Link Box -->
    <p class="mt-3 font-bold">Links</p>
    <Textarea class="w-full" v-model="links" rows="10" />

    <!-- verify links -->
    <Button class="w-full mt-2" label="Verify Links" @click="verify" />

    <DataTable v-show="verifyVisible" class="mt-2" :value="verifiedLinks">
        <Column field="url" header="Url"></Column>
        <Column field="status" header="Status"></Column>
    </DataTable>

    <!-- Save buttons -->
    <template #footer>
        <!-- disable if any verified links are not Valid-->
        <Button v-show="verifyVisible" class="w-full mt-2" label="Save" @click="save"
                :disabled="verifiedLinks.filter(e => e.status !== 'Valid').length > 0 || asset === null"/>
    </template>

</Dialog>
</template>

<script>
export default {
    name: "LinkRemovalModal",

    data() {
        return {
            visible: false,
            verifyVisible: false,
            assets: [],

            links: "",
            verifiedLinks: [],
            asset: null,

            loading: false,
        }
    },

    mounted() {
        this.init();
    },

    unmounted() {
        this.init();
    },

    methods: {
        init() {
            this.visible = false;
            this.assets = [];
            this.links = "";
            this.asset = null;
            this.verifiedLinks = [];
        },

        searchAssets(event) {
            this.assets = [];
            let activeAssets = this.$store.getters.getActiveAssetData;

            if (event.query.length < 3) {
                activeAssets.forEach(asset => this.assets.push(asset.id + ":" + asset.name) )
            } else {
                activeAssets.forEach(
                    asset => {
                        if (asset.name.toLowerCase().includes(event.query.toLowerCase())) {
                            this.assets.push(asset.id + ":" + asset.name);
                        }
                    }
                )
            }
        },

        show() {
            this.visible = true;
        },

        verify() {
            // for each link
            let splitLinks = this.links.split("\n");
            this.verifiedLinks = [];

            for (let i = 0; i < splitLinks.length; i++) {
                let link = splitLinks[i];
                let status = null;

                if (link.trim() === "") {
                    // check isn't empty
                    status = "Empty";
                } else if (splitLinks.indexOf(link) !== i) {
                    // check duplicates
                    status = "Duplicate";
                } else if (/^(http|https):\/\//i.test(link) === false) {
                    // check is url
                    status = "Not a URL";
                } else {
                    // check is valid
                    status = "Valid";
                }

                // add to verified links
                this.verifiedLinks.push({url: link, status: status});
            }

            this.verifyVisible = true;
        },

        save() {
            console.debug(this.asset, this.links);
            // split links by newline
            let submissionlinks = this.links.split("\n");

            // remove empty lines
            submissionlinks = submissionlinks.filter(link => link.trim() !== "");

            // remove duplicates
            submissionlinks = [...new Set(submissionlinks)];

            // verify links are urls
            let urlRegex = new RegExp("^(http|https)://", "i");
            submissionlinks = submissionlinks.filter(link => urlRegex.test(link));

            this.$factory.linkRemoval.addTakedown(this.asset.split(":")[0], submissionlinks)
                .then(() => {
                    this.$toast.add({severity: "success", summary: "Success", detail: "Links submitted for removal"});
                    this.init();
                    this.visible = false;
                    this.$emitter.emit('link-removal-refresh');
                })
                .catch(() => {
                    this.$toast.add({severity: "error", summary: "Error", detail: "Failed to submit links for removal"});
                })
        },
    }
}
</script>

<style scoped>

</style>
