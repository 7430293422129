<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- actions -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3 grid">
                    <div class="col-6">
                        <Button :label="selectedRemovals.length > 0 ? 'Approve Selected' : 'Select at least one removal'" class="w-full bg-green-400 border-green-500" @click="multiApprove" :disabled="selectedRemovals.length === 0"/>
                    </div>
                    <div class="col-6">
                        <Button :label="selectedRemovals.length > 0 ? 'Delete Selected' : 'Select at least one removal'" class="w-full bg-orange-400 border-orange-500" @click="multiDelete" :disabled="selectedRemovals.length === 0"/>
                    </div>
                    <div class="col-12">
                        <Button class="w-full" label="Add New Removal Request" @click="addNewRemoval" />
                    </div>
                </div>
            </div>

            <!-- body -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <DataTable :value="removals" show-gridlines lazy :loading="loading"
                               v-model:filters="filters"  filter-display="menu"
                               @page="onPageChange($event)" @filter="onFilterChange($event)"
                               @select-all-change="onSelectAllChange($event)" @row-select="onRowSelect" @row-unselect="onRowUnselect"
                               v-model:selection="selectedRemovals" :total-records="totalRecords"
                               paginator :rows=lazyParams.rows :rows-per-page-options="[100, 200, 500]"
                               paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink">
                        <template #loading> Loading link removal requests. Please wait. </template>

                        <Column selectionMode="multiple" style="max-width: 5rem"/>
                        <Column field="link" header="Link" :showFilterMatchModes="false">
                            <template #body="{ data }">
                                <div v-tooltip.top="data.link" @click="showStory(data.id)" class="cursor-pointer hover:text-surface-900">
                                    {{ data.link }}
                                </div>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by link" />
                            </template>
                        </Column>
                        <Column field="name" header="Name" :showFilterMatchModes="false" style="max-width: 150px">
                            <template #body="{ data }">
                                {{ data.name }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by provider name" />
                            </template>
                        </Column>
                        <Column field="asset_pair" header="Asset" :showFilterMatchModes="false" style="max-width: 200px">
                            <template #body="{ data }">
                                {{ data.asset_pair }}
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by asset" />
                            </template>
                        </Column>
                        <Column field="checked" header="Checked" :showFilterMatchModes="false" style="max-width: 100px">
                            <template #body="{ data }">
                                <div class="flex align-items-center">
                                    <i class="pi text-2xl" :class="{
                                    'pi-check-circle text-green-500': data.checked,
                                    'pi-times-circle text-red-500': !data.checked
                                }"></i>
                                </div>
                            </template>
                            <template #filter="{ filterModel }">
                                <Checkbox v-model="filterModel.value" :binary="true" />
                            </template>
                        </Column>
                        <Column field="notified" header="Notified" :showFilterMatchModes="false" style="max-width: 100px">
                            <template #body="{ data }">
                                <div style="text-align:center">
                                    <i class="pi text-2xl" :class="{
                                    'pi-check-circle text-green-500': data.notified,
                                    'pi-times-circle text-red-500': !data.notified
                                }"></i>
                                </div>
                            </template>
                            <template #filter="{ filterModel }">
                                <Checkbox v-model="filterModel.value" :binary="true" />
                            </template>
                        </Column>
                        <Column field="removed" header="Removed" :showFilterMatchModes="false" style="max-width: 100px">
                            <template #body="{ data }">
                                <div style="text-align:center">
                                    <i class="pi text-2xl" :class="{
                                    'pi-check-circle text-green-500': data.removed,
                                    'pi-times-circle text-red-500': !data.removed
                                }"></i>
                                </div>
                            </template>
                            <template #filter="{ filterModel }">
                                <Checkbox v-model="filterModel.value" :binary="true" />
                            </template>
                        </Column>
                        <Column field="actions" header="Actions" :showFilter="false" :sortable="false" style="max-width: 10rem">
                            <template #body="{ data }">
                                <div class="flex justify-content-between">
                                    <Button icon="pi pi-check" class="mx-2 p-button-rounded p-button-sm p-button-outlined" @click="approve($event, data.link)" v-tooltip.top="'Approve this link only'"/>
                                    <Button icon="pi pi-trash" class="mx-2 p-button-rounded p-button-critical p-button-sm p-button-outlined" @click="remove($event, data.link)" v-tooltip.top="'Remove this link only'"/>
                                </div>
                            </template>
                        </Column>

                    </DataTable>
                </div>
            </div>


        </div>
    </div>
</div>
<LinkRemovalModal ref="linkRemovalModal" />
<LinkRemovalStoryModal ref="linkRemovalStoryModal" />
</template>

<script>
import LinkRemovalModal from "@/components/modals/LinkRemovalModal";
import {FilterMatchMode} from "primevue/api";
import LinkRemovalStoryModal from "@/components/modals/LinkRemovalStoryModal";

export default {
    name: "LinkRemoval",
    components: {
        LinkRemovalModal,
        LinkRemovalStoryModal
    },

    data() {
        return {
            totalRecords: 0,
            removals: [],
            lazyParams: {
                first: 0,
                rows: 10,
                sortField: "Id",
                sortOrder: 1,
                filters: {}
            },

            filters: {
                link: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                asset_pair: { value: null, matchMode: FilterMatchMode.CONTAINS },
                checked: { value: false, matchMode: FilterMatchMode.EQUALS },
                notified: { value: null, matchMode: FilterMatchMode.EQUALS },
                removed: { value: null, matchMode: FilterMatchMode.EQUALS },
            },

            loading: false,

            // Table selection variables
            selectedRemovals: [],
            selectAll: false,
        }
    },

    mounted() {
        this.lazyParams.filters = this.filters;
        this.getRemovals();

        this.$emitter.on("link-removal-refresh", () => {
            console.debug("link-removal-refresh");
            this.getRemovals();
        });
    },

    methods: {
        addNewRemoval() {
            this.$refs.linkRemovalModal.show();
        },

        getRemovals() {
            console.debug("Getting removals", this.lazyParams, this.rows)
            this.loading = true;
            this.$factory.linkRemoval.getTakedowns(this.lazyParams).then((response) => {
                console.debug(response)
                this.removals = response.data;
                this.totalRecords = response.totalRecords;
                this.loading = false;
            }).catch(() => {
                this.loading = false;
                this.$emitter.emit("bad-toast", "Failed to get link removal requests. Please try again later.");
            });
        },

        onPageChange(event) {
            console.debug("onPageChange", event)
            this.lazyParams.first = event.first;
            this.lazyParams.rows = event.rows;
            this.getRemovals()
        },

        onFilterChange() {
            this.lazyParams.filters = this.filters;
            this.getRemovals();
        },

        approve(event, link) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to approve this link?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$factory.linkRemoval.approveLink(link).then(() => {
                        this.getRemovals();
                        this.$emitter.emit("good-toast", "Link approved successfully.");
                    }).catch(() => {
                        this.$emitter.emit("bad-toast", "Failed to approve link. Please try again later.");
                    })
                },
            });
        },

        remove(event, link) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to remove this link?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$factory.linkRemoval.removeLink(link).then(() => {
                        this.getRemovals();
                        this.$emitter.emit("good-toast", "Link removed successfully.");
                    }).catch(() => {
                        this.$emitter.emit("bad-toast", "Failed to remove link. Please try again later.");
                    })
                },
            });
        },

        onSelectAllChange(event) {
            console.debug("onSelectAllChange")
            this.selectAll = event.checked;
            if (this.selectAll) {
                this.selectedRemovals = this.removals;
            } else {
                this.selectedRemovals = [];
            }
        },

        onRowSelect() {
            console.debug("onRowSelect")
            this.selectAll = this.selectedRemovals.length === this.removals.length;
        },

        onRowUnselect() {
            console.debug("onRowUnselect")
            this.selectAll = false;
        },

        multiApprove() {
            console.debug(this.selectedRemovals);
            let linksList = this.selectedRemovals.map((removal) => removal.link);
            this.$factory.linkRemoval.approveMultipleLinks(linksList).then(() => {
                this.getRemovals();
                this.$emitter.emit("good-toast", "Selected links approved successfully.");
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Failed to approve selected links. Please try again later.");
            });
        },

        multiDelete() {
            console.debug(this.selectedRemovals);
            let linksList = this.selectedRemovals.map((removal) => removal.link);
            this.$factory.linkRemoval.removeMultipleLinks(linksList).then(() => {
                this.getRemovals();
                this.$emitter.emit("good-toast", "Selected links removed successfully.");
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Failed to remove selected links. Please try again later.");
            });
        },

        showStory(linkId) {
            this.$refs.linkRemovalStoryModal.show(linkId);
        }
    },
}
</script>

<style scoped>

</style>
