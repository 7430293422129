<template>
    <Dialog v-model:visible="visible" modal header="Link Removal Story" :style="{ width: '50vw' }">
        <div v-if="story.length === 0">
            <p class="mt-3 font-bold">No story found</p>
        </div>
        <div v-else>
            <div v-for="storyItem in story" :key="storyItem.id">
                <p class="mt-3 font-bold">{{ storyItem.type }}</p>
                <p>{{ storyItem.description }}</p>
                <p>{{ storyItem.timestamp }}</p>
                <Divider />
            </div>
        </div>
    </Dialog>
</template>

<script>
export default {
    name: "LinkRemovalStoryModal",

    data() {
        return {
            visible: false,
            linkId: null,
            story: []
        }
    },

    methods: {
        init() {
            this.visible = false;
            this.linkId = null;

            this.story = []
        },

        show(linkId) {
            this.init();
            this.linkId = linkId;
            this.visible = true;

            console.debug("LinkRemovalStoryModal.show", this.linkId);

            this.getStory();
        },

        getStory() {
            this.$factory.linkRemoval.getStory(this.linkId)
                .then(response => {
                    this.story = response;
                })
                .catch(error => {
                    console.error("LinkRemovalStoryModal.getStory", error);
                });
        }
    }
}
</script>

<style scoped>

</style>
